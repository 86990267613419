import request from '@/utils/request'

export function getTemplateList(params) {
    return request({
        url: `/taskTemplate/page`,
        method: 'GET',
        params,
    })
}

export function createTemplate(data) {
    return request({
        url: data.id ? `/taskTemplate/update/${data.id}` : `/taskTemplate/create`,
        method: 'POST',
        data
    })
}

export function getTemplateDetail(params) {
    return request({
        url: `/taskTemplate/getById`,
        method: 'GET',
        params,
    })
}

export function saveTemplateDetail(data) {
    return request({
        url: `/taskTemplate/setTemplateAndTasks`,
        method: 'POST',
        data,
    })
}

export function deleteTemplateDetail(data) {
    return request({
        url: `/taskTemplate/delete`,
        method: 'POST',
        data,
    })
}








