<template>
  <div class="full-card">
    <div class="display-f justify-between align-items-c margin-b15">
      <div>
        <a-form-model layout="inline" :rules="rules">
          <a-form-model-item label="项目任务模版名称">
            <a-input v-model="detail.templateName"></a-input>
          </a-form-model-item>
          <a-form-model-item label="浏览下载权限">
            <a-select mode="multiple" v-model="detail.postIds" placeholder="请选择浏览下载权限" style="width: 400px">
              <a-select-option :value="item.id" v-for="(item, index) in jobList" :key="item.id">{{ item.postName }}（{{ item.deptName}}）</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div>
        <a-button type="primary" icon="save" @click="save('create')" v-has-permission="['addTask']">保存</a-button>
        <a-button class="margin-l20 width-120" type="danger" icon="delete" @click="delItem" v-has-permission="['deleteCustomerStakeholder']">删除</a-button>
      </div>
    </div>
    <a-card>
      <a-table
          :columns="columns"
          :data-source="list"
          :loading="tableLoading"
          :pagination="false"
          rowKey="id"
          bordered
      >
        <template slot="taskName" slot-scope="text, record">
          <div class="display-f align-items-c">
            <img src="@/assets/image/common/star_fill.png" alt="" class="margin-r8" @click="record.taskLevel = 2" v-if="record.taskLevel === 1">
            <img src="@/assets/image/common/star.png" alt="" class="margin-r8"  @click="record.taskLevel = 1" v-else>
            <a-input
                :value="text"
                :disabled="!canModify"
                @change="e => itemChange(e.target.value, record.id, 'taskName')"
            ></a-input>
          </div>
        </template>
        <template slot="taskCategoryId" slot-scope="text, record">
          <a-select
              :value="text"
              @change="e => itemChange(e, record.id, 'taskCategoryId')"
              style="width: 100%"
              :disabled="!canModify"
              placeholder="请选择任务类型">
            <a-select-option :value="item.id" v-for="(item, index) in taskTypeList" :key="item.id">
              {{ item.categoryName }}
            </a-select-option>
          </a-select>
        </template>
        <template slot="planStartDate" slot-scope="text, record">
          <a-date-picker :value="text ? moment(text) : null" :disabled="!canModify" @change="(date, dateStr) => itemChange(dateStr, record.id, 'planStartDate')" style="width: 100%"/>
        </template>
        <template slot="planEndDate" slot-scope="text, record">
          <a-date-picker :value="text ? moment(text) : null" :disabled="!canModify" @change="(date, dateStr) => itemChange(dateStr, record.id, 'planEndDate')" style="width: 100%"/>
        </template>
        <template slot="durationDays" slot-scope="text, record">
          <a-input
              :value="text"
              :disabled="!canModify"
              @change="e => itemChange(e.target.value, record.id, 'durationDays')"
          ></a-input>
        </template>
      </a-table>
      <div class="margin-t15">
        <a-button type="primary" icon="plus" @click="addRow">新增一行</a-button>
      </div>
    </a-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { deleteTemplateDetail, getTemplateDetail, saveTemplateDetail } from '../../../api/project/template'
import { getTaskTypeList } from '../../../api/task/task'
import moment from 'moment'
import { getJobList } from '@/api/humanResources/job'
export default {
  name: 'templateDetail',
  data () {
    return {
      columns: [
        {
          title: '任务名称',
          dataIndex: 'taskName',
          scopedSlots: { customRender: 'taskName' },
          width: 300
        },
        {
          title: '任务类型',
          dataIndex: 'taskCategoryId',
          scopedSlots: { customRender: 'taskCategoryId' },
          width: 250
        },
        {
          title: '计划开始日期',
          dataIndex: 'planStartDate',
          scopedSlots: { customRender: 'planStartDate' }
        },
        {
          title: '计划结束日期',
          dataIndex: 'planEndDate',
          scopedSlots: { customRender: 'planEndDate' }
        },
        {
          title: '持续时间（天）',
          dataIndex: 'durationDays'
        }
      ],
      list: [],
      total: 0,
      tableLoading: true,
      color: {
        1: 'font-c3',
        2: 'red',
        3: 'green',
      },
      taskTypeList: [],
      canModify: false,
      detail: {},
      jobList: [],
      rules: {
        templateName: [
          { required: true, message: '请输入项目任务模版名称', trigger: 'blur' },
        ]
      },
      cacheId: 100000
    }
  },
  mounted () {
    this.getDetail()
    this.getTaskType()
    this.getJob()
    this.canModify = this.authority.editProjectTaskTemplate
  },
  methods: {
    moment,
    getDetail () {
      getTemplateDetail({
        id: this.$route.query.id
      }).then(res => {
        this.detail = res.data
        this.list = res.data.relationTaskTemplateVOList || []
        this.tableLoading = false
      })
    },
    getTaskType () {
      getTaskTypeList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.taskTypeList = res.data.list
      })
    },
    save () {
      saveTemplateDetail({
        relationTaskTemplates: this.list,
        taskTemplateId: this.$route.query.id,
        templateName: this.detail.templateName,
        postIds: this.detail.postIds
      }).then(() => {
        this.$message.success('保存成功！')
      })
    },
    delItem () {
      this.$confirm({
        title: '提示',
        content: '确认删除这条数据？',
        centered: true,
        onOk: () => {
          deleteTemplateDetail({
            ids: [this.$route.query.id]
          }).then(() => {
            this.$message.success('删除成功！')
            this.$router.go(-1)
          })
        }
      })
    },
    itemChange (value, id, column) {
      const newData = [...this.list];
      const target = newData.find(item => id === item.id);
      if (target) {
        target[column] = value;
        this.list = newData;
      }
    },
    getJob () {
      getJobList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.jobList = res.data.list
      })
    },
    addRow () {
      this.list.push({id: this.cacheId++})
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped>

</style>
